;(function () {
   'use strict'

   angular
      .module('internalWebsiteApp')
      .controller(
         'ModalTruckCarrierFormController',
         function (
            $scope,
            $uibModalInstance,
            logisticsData,
            alertService,
            carrierTypeOptions,
            logisticsRootState,
            currentTrip,
            truckloadId,
            setBusyTrue,
            setBusyFalse,
            existingCarrierData,
            onSubmitSuccess,
         ) {
            var currentTruckload = currentTrip.truckloadsById[truckloadId]

            function truckPeopleOptionsMapper(optionsObj) {
               return optionsObj.map(function (entry) {
                  return {
                     label: entry.name,
                     value: entry.id,
                  }
               })
            }

            $scope.isFieldDisabled = function (fieldKey) {
               return currentTruckload && currentTruckload.disabledCarrierInfoFormFields.includes(fieldKey)
            }

            $scope.truckCarrierFields = {
               truckDriverPersonId: {
                  label: 'Driver',
                  type: 'select',
                  isRequired: true,
                  options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.truckDrivers),
               },
               carrierTypeId: {
                  label: 'Type',
                  type: 'select',
                  isRequired: true,
                  onChange: function () {
                     // Clear Drive Azure fields if not Drive Azure (type 1)
                     if ($scope.truckCarrierFormValues.carrierTypeId !== 1) {
                        delete $scope.truckCarrierFormValues.driveAzureMileage
                        delete $scope.truckCarrierFormValues.driveAzureDeliveryRate
                        delete $scope.truckCarrierFormValues.driveAzurePerStopFee
                     }
                     // Clear Contractor fields if not Contractor (type 3)
                     if ($scope.truckCarrierFormValues.carrierTypeId !== 3) {
                        delete $scope.truckCarrierFormValues.carrierContractorPersonId
                        delete $scope.truckCarrierFormValues.carrierContractorMileage
                        delete $scope.truckCarrierFormValues.carrierContractorDeliveryRate
                        delete $scope.truckCarrierFormValues.carrierContractorPerStopFee
                     }
                  },
                  options: angular.extend([], carrierTypeOptions),
               },
               driveAzureMileage: {
                  label: 'Drive Azure Mileage',
                  type: 'int',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 1',
                  isRequired: false,
                  inputSuffix: 'miles',
               },
               driveAzureDeliveryRate: {
                  // entry in dollars of the delivery rate per mile
                  label: 'Drive Azure Delivery Rate',
                  type: 'dollar',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 1',
                  isRequired: false,
               },
               driveAzurePerStopFee: {
                  // entry in dollars of the cost per stop
                  label: 'Drive Azure Per Stop Fee',
                  type: 'dollar',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 1',
                  isRequired: false,
               },
               carrierContractorPersonId: {
                  label: 'Contractor',
                  type: 'select',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 3',
                  isRequired: true,
                  options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.carrierContractors),
               },
               carrierContractorMileage: {
                  label: 'Contractor Mileage',
                  type: 'int',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 3',
                  isRequired: false,
                  inputSuffix: 'miles',
               },
               carrierContractorDeliveryRate: {
                  // entry in dollars of the delivery rate per mile
                  label: 'Contractor Delivery Rate',
                  type: 'dollar',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 3',
                  isRequired: false,
               },
               carrierContractorPerStopFee: {
                  // entry in dollars of the cost per stop
                  label: 'Contractor Per Stop Fee',
                  type: 'dollar',
                  showIf: 'truckCarrierFormValues.carrierTypeId === 3',
                  isRequired: false,
               },
               isCarrierShuttle: {
                  label: 'Is Shuttle',
                  type: 'toggle',
                  onChange: function () {
                     if (!$scope.truckCarrierFormValues.isCarrierShuttle) {
                        delete $scope.truckCarrierFormValues.carrierShuttleType
                        delete $scope.truckCarrierFormValues.carrierShuttleQuote
                        delete $scope.truckCarrierFormValues.carrierShuttleMileage
                        delete $scope.truckCarrierFormValues.carrierShuttleMileageRate
                     }
                  },
                  labelIfTrue: 'Yes',
                  labelIfFalse: 'No',
                  default: false,
               },
               carrierShuttlePersonId: {
                  label: 'Shuttle',
                  type: 'select',
                  showIf: '!!truckCarrierFormValues.isCarrierShuttle',
                  isRequired: true,
                  options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.carrierShuttles),
               },
               carrierShuttleQuote: {
                  // entry in dollars of the shuttle cost
                  label: 'Shuttle Quote',
                  type: 'dollar',
                  showIf: '!!truckCarrierFormValues.isCarrierShuttle',
                  isRequired: true,
               },
               carrierShuttleMileage: {
                  label: 'Shuttle Mileage',
                  type: 'int',
                  showIf: '!!truckCarrierFormValues.isCarrierShuttle',
                  isRequired: false,
                  inputSuffix: 'miles',
               },
               carrierShuttleMileageRate: {
                  // entry in dollars of the shuttle mileage rate
                  label: 'Shuttle Mileage Rate',
                  type: 'dollar',
                  showIf: '!!truckCarrierFormValues.isCarrierShuttle',
                  isRequired: false,
               },
            }

            $scope.truckCarrierFormValues = existingCarrierData ? angular.extend({}, existingCarrierData) : {}

            Object.entries($scope.truckCarrierFields).forEach(function ([key, value]) {
               if (
                  !existingCarrierData ||
                  (!Object.hasOwn(existingCarrierData, key) && Object.hasOwn(value, 'default'))
               ) {
                  $scope.truckCarrierFormValues[key] = value.default
               }
            })

            $scope.submitTruckCarrierForm = function (truckCarrierForm) {
               if (truckCarrierForm.$invalid) {
                  return
               }

               $uibModalInstance.close()

               setBusyTrue('Updating truck carrier info')

               logisticsData
                  .updateTruckload(
                     angular.extend(
                        {
                           id: currentTruckload.id,
                        },
                        $scope.truckCarrierFormValues,
                     ),
                  )
                  .then(function (updatedTruckload) {
                     onSubmitSuccess(updatedTruckload)
                  })
                  .catch(function (error) {
                     console.error(error)
                     alertService.errorMessage('Error updating truck carrier info')
                  })
                  .finally(setBusyFalse)
            }
         },
      )
})()
